import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const Languages = ['en', 'esp', 'fr', 'pt', 'jp', 'de', 'dk', 'se', 'pl', 'it', 'no']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    whitelist: Languages,


    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;
