import { useTranslation } from 'react-i18next'

import styles from '../styles/team.module.scss'
import imgStyles from '../styles/img-styles.module.scss'

const Team = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <img className={imgStyles.imgContainer} src='/images/Helicopter.png' />
      <div className={styles.textContainer}>
        <h1>Meet The Team</h1>
        <div className={styles.gridContainer}>
          <div className={`${styles.gridPic} ${styles.iPic}`}>
            <img src='/images/Ian.jpeg' width='300px' height='400px'/>
          </div>
          <div className={`${styles.gridText} ${styles.iDesc}`}>
            <h2>{t("Ian_Name")}</h2>
            <p>{t("Ian_Role")}</p>
            <p>{t("Ian_Email")}</p>
            <p>{t("Ian_Desc")}</p>
          </div>
          <div className={`${styles.gridText} ${styles.dDesc}`}>
            <h2>{t("Daren_Name")}</h2>
            <p>{t("Daren_Role")}</p>
            <p>{t("Daren_Email")}</p>
            <p>{t("Daren_Desc")}</p>
          </div>
          <div className={`${styles.gridPic} ${styles.dPic}`}>
            <img src='/images/Daren.jpeg' width='300px' height='400px' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team