const languages = {
    languagesArray: [{
            lang: 'English',
            langCode: 'en',
            flagCode: 'GB'
        },{
            lang: 'Español',
            langCode: 'esp',
            flagCode: 'ES'
        },{
            lang: 'Deutsch',
            langCode: 'de',
            flagCode: 'DE'
        },{
            lang: 'Dansk',
            langCode: 'dk',
            flagCode: 'DK'
        },{
            lang: 'Français',
            langCode: 'fr',
            flagCode: 'FR'
        },{
            lang: 'Italiana',
            langCode: 'it',
            flagCode: 'IT'
        },{
            lang: '日本人',
            langCode: 'jp',
            flagCode: 'JP'
        },{
            lang: 'Polskie',
            langCode: 'pl',
            flagCode: 'PL'
        },{
            lang: 'Norsk',
            langCode: 'no',
            flagCode: 'NO'
        },{
            lang: 'Português',
            langCode: 'pt',
            flagCode: 'PT'
        }
    ]
}

export default languages