import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ClickableTile } from '@carbon/react'
import { ArrowRight } from '@carbon/react/icons'

import styles from '../styles/index.module.scss'
import imgStyles from '../styles/img-styles.module.scss'

const Home = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.homeContainer}>
      <img className={imgStyles.imgContainer} src='/images/uav.png' />

      <div className={styles.textContainer}>
        <h1 className={styles.title}>Downey RCM Consultancy</h1>
        <p>{t("Home_para_1")}</p>
        <p>{t("Home_para_2")}</p>
        <p>{t("Home_para_3")}</p>
      </div>

      <div className={styles.tileContainer}>
        <ClickableTile className={styles.tile} onClick={() => navigate('/services')}>
          <img className={styles.tileImg} src='/images/services.jpg' width='300px' height='200px' />
          <p>We provide first class services</p>
          <span>Go to our services <ArrowRight className={styles.arrow} size={12}/></span>
        </ClickableTile>
        <ClickableTile  className={styles.tile} onClick={() => navigate('/casestudies')}>
          <img src='/images/casestudies.jpg' width='300px' height='200px' />
          <p>We have proven results</p>
          <span>Go to our case studies <ArrowRight className={styles.arrow} size={12}/></span>
        </ClickableTile>
        <ClickableTile  className={styles.tile} onClick={() => navigate('/team')}>
          <img src='/images/team.jpg' width='300px' height='200px' />
          <p>We have an experienced team</p>
          <span>Go to our team <ArrowRight className={styles.arrow} size={12}/></span>
        </ClickableTile>
      </div>
    </div>
  )
}

export default Home
