import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormGroup, TextInput, TextArea, Button } from "@carbon/react"
import emailjs from '@emailjs/browser'

import styles from '../styles/contact.module.scss'

const Contact = () => {
  const { t, i18n } = useTranslation()

  const [messageSent, setMessageSent] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [isSending, setIsSending] = useState(false)

  const contactMessage = () => {
    setTimeout(() => {
      setMessageSent(false)
    }, 30000)

    if (messageSent)
      return <p style={{color: "green"}} className="response">{responseMessage}</p>
    else if (!messageSent)
      return <p></p>
    else
      return <p style={{color: "red"}} className="response">{responseMessage}</p>
  }

  const sendEmail = (e) => {
    e.preventDefault()

    setIsSending(true)
    setResponseMessage("Please Wait")
    if (!messageSent) {
      emailjs.sendForm('service_00y5g5n', 'template_4fzxmim', '#form', 'X9b_-nmd5gp9snhgD')
        .then(() => {
          setMessageSent(true)
          setResponseMessage("Success - Message Sent")
          setIsSending(false)
        }, () => {
          setMessageSent(false)
          setResponseMessage("Failure - Message Not Sent")
          setIsSending(false)
        })
    }
  }

  return(
    <div className={styles.contact}>
      <h1>Contact Us</h1>
      <Form id='form' className="contact__form" onSubmit={sendEmail}>
        <FormGroup>
          <TextInput id="name" invalidText="Invalid Name" labelText="Name" placeholder="John Doe" required name="name"/>
          <TextInput id="email" invalidText="Invalid Email" labelText="Email" placeholder="johndoe@gmail.com" required name="email"/>
          <TextInput id="company" invalidText="Invalid Company" labelText="Company (optional)" name="company"/>
          <TextArea
            cols={120}
            helperText="Maximum 500 words"
            id="message"
            invalidText="Invalid Message."
            labelText="Message"
            placeholder="Enter Message Here"
            rows={10}
            required
            name="message"
          />
        </FormGroup>
        <div className={styles.submitButton}>
          <Button kind="primary" type="submit">Submit</Button>
        </div>
        {isSending && <p className={styles.responseMessage}>Please Wait...</p>}
        {!isSending && <p  className={styles.responseMessage}>{responseMessage}</p>}
      </Form>
    </div>
  )
}

export default Contact