import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import { Loading } from "@carbon/react"

import './index.scss'
import App from './App'

import './i18n.js'

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loading />} >
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
)
