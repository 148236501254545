import { useTranslation } from 'react-i18next'
import { Tabs, TabList, TabPanels, TabPanel, Tab, TableHead, TableRow, TableHeader, TableBody, TableCell, Table } from '@carbon/react'

import imgStyles from '../styles/img-styles.module.scss'
import styles from '../styles/services.module.scss'

const Rcm = () => {
  const { t } = useTranslation()

  return (
    <div>
      <img className={imgStyles.imgContainer} src='/images/Hawk2.png'></img>
      <div className={styles.textContainer}>
        <h1>Our Case Studies</h1>
        <Tabs>
          <TabList aria-label="Services tabs">
            <Tab className={styles.tab}>Fixed Wing RCMA Case Study</Tab>
            <Tab className={styles.tab}>Rotorcraft Case Study</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <h2>Fixed Wing RCMA Case Study</h2>
              <p>{t('Case_1_overview')}</p>

              <h3>Requirements</h3>
              <p>{t('Case_1_para_1')}</p>
              <ul>
                <li>{t('Case_1_point_1')}</li>
                <li>{t('Case_1_point_2')}</li>
                <li>{t('Case_1_point_3')}</li>
              </ul>
              <p>{t('Case_1_para_2')}</p>
              <ul>
                <li>{t('Case_1_point_4')}</li>
                <li>{t('Case_1_point_5')}</li>
                <li>{t('Case_1_point_6')}</li>
                <li>{t('Case_1_point_7')}</li>
              </ul>

              <h3>Results</h3>
              <p>{t('Case_1_results')}</p>
              <ul>
                <li>{t('Case_1_point_8')}</li>
                <li>{t('Case_1_point_9')}</li>
              </ul>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader >System</TableHeader>
                    <TableHeader>Cumulative Pre Review Maintenance <br/> Hours/£</TableHeader>
                    <TableHeader>Cumulative Post Review Maintenance <br/> Hours/£</TableHeader>
                    <TableHeader>% Change</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell key={0.0}>Each Aircraft Sum MMH</TableCell>
                    <TableCell key={0.1}>992.68</TableCell>
                    <TableCell key={0.2}>645.06</TableCell>
                    <TableCell key={0.3}>42.88%</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell key={1.0}>Each Aircraft Sum MMH (£)</TableCell>
                    <TableCell key={1.1}>£69,487.48</TableCell>
                    <TableCell key={1.2}>£45,154.03</TableCell>
                    <TableCell key={1.3}>42.88%</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell key={2.0}>Fleet Sum MMH (£)</TableCell>
                    <TableCell key={2.1}>£3,613,349.13</TableCell>
                    <TableCell key={2.2}>£2,348,009.30</TableCell>
                    <TableCell key={2.3}>42.88%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <h3>Additional Benefits</h3>
              <p>{t('Case_1_additional')}</p>
              <ul>
                <li>{t('Case_1_point_10')}</li>
                <li>{t('Case_1_point_11')}</li>
              </ul>

              <div className={styles.graphs}>
                <img src='/images/Case1_graph1.png' width='700' height='350'/><br />
              </div>
              <div className={styles.graphs}>
                <img src='/images/Case1_graph2.png' width='700' height='380'/>
              </div>
              
            </TabPanel>
            <TabPanel>
              <h2>Rotorcraft Case Study</h2>
              <p>{t('Case_2_overview')}</p>

              <h3>Requirements</h3>
              <p>{t('Case_2_para_1')}</p>
              <ul>
                <li>{t('Case_2_point_1')}</li>
                <li>{t('Case_2_point_2')}</li>
                <li>{t('Case_2_point_3')}</li>
              </ul>
              <p>{t('Case_2_para_2')}</p>
              <ul>
                <li>{t('Case_2_point_4')}</li>
                <li>{t('Case_2_point_5')}</li>
                <li>{t('Case_2_point_6')}</li>
              </ul>

              <h3>Results</h3>
              <p>{t('Case_2_results')}</p>
              <ul>
                <li>{t('Case_2_point_7')}</li>
                <li>{t('Case_2_point_8')}</li>
                <li>{t('Case_2_point_9')}</li>
              </ul>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>System</TableHeader>
                    <TableHeader>Cumulative Pre Review Maintenance <br/> Hours/£</TableHeader>
                    <TableHeader>Cumulative Post Review Maintenance <br/> Hours/£</TableHeader>
                    <TableHeader>% Change</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell key={0.0}>Each Aircraft Sum MMH</TableCell>
                    <TableCell key={0.1}>4945</TableCell>
                    <TableCell key={0.2}>3538</TableCell>
                    <TableCell key={0.3}>28.46%</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell key={1.0}>Each Aircraft Sum MMH (£)</TableCell>
                    <TableCell key={1.1}>£346,175</TableCell>
                    <TableCell key={1.2}>£247,645</TableCell>
                    <TableCell key={1.3}>28.46%</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell key={2.0}>Fleet Sum MMH (£)</TableCell>
                    <TableCell key={2.1}>£10,731,417</TableCell>
                    <TableCell key={2.2}>£7,676,982</TableCell>
                    <TableCell key={2.3}>28.46%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <h3>Additional Benefits</h3>
              <p>{t('Case_2_additional')}</p>
              <ul>
                <li>{t('Case_2_point_10')}</li>
                <li>{t('Case_2_point_11')}</li>
              </ul>

              <div className={styles.graphs}>
                <img src='/images/Case2_graph1.png' width='700px' height='400px'/><br />
              </div>
              <div className={styles.graphs}>
                <img src='/images/Case2_graph2.png' width='700px' height='400px'/>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  )
}

export default Rcm