import { useTranslation } from 'react-i18next'
import { Tabs, TabList, TabPanels, TabPanel, Tab} from '@carbon/react'

import imgStyles from '../styles/img-styles.module.scss'
import styles from '../styles/services.module.scss'

const Rcm = () => {
  const { t } = useTranslation()

  return (
    <div>
      <img className={imgStyles.imgContainer} src='/images/Spitfire2.png' />
      <div className={styles.textContainer}>
        <h1>Our Services</h1>
        <Tabs>
          <TabList aria-label="Services tabs">
            <Tab className={styles.tab}>Reliability Centred Maintenance</Tab>
            <Tab className={styles.tab}>Reliability & Maintainability</Tab>
            <Tab className={styles.tab}>Technical Support</Tab>
            <Tab className={styles.tab}>In-Service Performance</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <h2>Reliability Centred Maintenance</h2>
              <p>{t("RCM_para_1")}</p>
              <p>{t("RCM_para_2")}</p>
            </TabPanel>
            <TabPanel>
              <h2>Reliability & Maintainability</h2>
              <p>{t("RM_para_1")}</p>
              <ul>
                  <li>{t("RM_point_1")}</li>
                  <li>{t("RM_point_2")}</li>
                  <li>{t("RM_point_3")}</li>
                  <li>{t("RM_point_4")}</li>
                  <li>{t("RM_point_5")}</li>
              </ul>
              <p>{t("RM_para_2")}</p>
              <p>{t("RM_para_3")}</p>
            </TabPanel>
            <TabPanel>
              <h2>Technical Support</h2>
              <p>{t("Tech_para_1")}</p>
              <ul>
                  <li>{t("Tech_point_1")}</li>
                  <li>{t("Tech_point_2")}</li>
                  <li>{t("Tech_point_3")}</li>
                  <li>{t("Tech_point_4")}</li>
                  <li>{t("Tech_point_5")}</li>
              </ul>
              <p>{t("Tech_para_2")}</p>
            </TabPanel>
            <TabPanel>
              <h2>In-Service Performance</h2>
              <p>{t("InService_para_1")}</p>
              <ul>
                  <li>{t("InService_point_1")}</li>
                  <li>{t("InService_point_2")}</li>
              </ul>
              <p>{t("InService_para_2")}</p>
              <p>{t("InService_para_3")}</p>
              <ul>
                  <li>{t("InService_point_3")}</li>
                  <li>{t("InService_point_4")}</li>
                  <li>{t("InService_point_5")}</li>
              </ul>
              <div className={styles.graphs}>
                <img src='/images/InService-pic1.png' width='800px' height='450px'/>
              </div>
              <p>{t("InService_para_4")}</p>
              <ul>
                  <li>{t("InService_point_6")}</li>
                  <li>{t("InService_point_7")}</li>
                  <li>{t("InService_point_8")}</li>
                  <li>{t("InService_point_9")}</li>
                  <li>{t("InService_point_10")}</li>
              </ul>
              <p>{t("InService_para_5")}</p>
              <div className={styles.graphs}>
                <img src='/images/InService-pic2.png' width='1000px' height='200px'/>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
        

        


        
      </div>
    </div>
  )
}

export default Rcm