import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction, HeaderPanel, HeaderNavigation, HeaderMenuItem } from '@carbon/react'
import { Translate } from '@carbon/react/icons'
import ReactCountryFlag from "react-country-flag"

import languages from '../utils/languages'

import styles from '../styles/nav.module.scss'

const Navigation = () => {
  const { t, i18n } = useTranslation()

  const [isLanguagePanelOpen, setIsLanguagePanelOpen] = useState(false)

  const changeLanaguage = (nextLang) => {
    i18n.changeLanguage(nextLang)
    setIsLanguagePanelOpen(false)
  }

  return (
    <Header aria-label="Downey RCM">
      <HeaderName href="/" prefix="Downey">
        RCM
      </HeaderName>

      <HeaderNavigation aria-label='Header nav'>
        <HeaderMenuItem href="/services">{t('Nav_services')}</HeaderMenuItem>
        <HeaderMenuItem href="/casestudies">{t('Nav_casestudies')}</HeaderMenuItem>
        <HeaderMenuItem href="/team">{t('Nav_team')}</HeaderMenuItem>
        <HeaderMenuItem href="/contact">{t('Nav_contact')}</HeaderMenuItem>
      </HeaderNavigation>

      <HeaderGlobalBar>
        <HeaderGlobalAction
          aria-label="Language Switcher"
          onClick={() => setIsLanguagePanelOpen(!isLanguagePanelOpen)}
          tooltipAlignment="end">
          <Translate size={24} />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
      <HeaderPanel aria-label="Header Panel" expanded={isLanguagePanelOpen} className={styles.langPanel}>
        {languages.languagesArray.map((languageObj) =>
          <button 
            className={i18n.language === languageObj.langCode ? `${styles.langButton} ${styles.langActive}` : `${styles.langButton}`} 
            onClick={() => changeLanaguage(languageObj.langCode)}
            key={languageObj.langCode}
          >
            <span className={styles.langText}>{languageObj.lang}</span>
            <ReactCountryFlag className={styles.langFlag} countryCode={languageObj.flagCode} svg />
          </button>
        )}
      </HeaderPanel>
    </Header>
  )
}

export default Navigation
