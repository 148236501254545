import React from 'react'
import { Route, Routes } from "react-router-dom"

import Navigation from './components/Navigation'
import Home from './pages/index'
import Services from './pages/services'
import CaseStudies from './pages/casestudies'
import Team from './pages/team'
import Contact from './pages/contact'

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/casestudies" element={<CaseStudies />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;